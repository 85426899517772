module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://www.technonavalia.com/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Technonavalia Sitio web oficial","short_name":"Technonavalia","description":"Navegación, Comunicación, AVIT, Seguridad, Hidroacústica, Energía | VSAT, TV, 4G, GMDSS, CCTV, Inspecciones de radio. Técnicos especializados ¡Contácta!","lang":"es","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#004c9d","theme_color":"#004c9d","localize":[{"start_url":"/en/","lang":"en","name":"Technonavalia Oficial Website","short_name":"Technonavalia","description":"Navigation, Communication, AVIT, Safety, Hydroacoustics, Energy | VSAT, TV, 4G, GMDSS, CCTV, Radio inspections. Specialized technicians Contact us!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e92dcd21756260880d463ef0d07a3e2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://technonavalia.com/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
